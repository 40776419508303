/* cSpell:disable */

export const t = (key: string, params?: Record<string, string | number>) => {
  const titles: Record<string, string> = {
    'Chapter {number}': 'Глава {number}',
    'Page not found': 'Страница не найдена',
    'Link on comment': 'Ссылка на комментарий',
    'Book not found': 'Книга не найдена',
    'Write comment...': 'Написать комментарий...',
    'Change: {date}': 'Изменено: {date}',
    'Payment not found': 'Платеж не найден',
    'Subscription successfull add': 'Подписка успешно активирована',
    'Are you sure want to delete comment?':
      'Вы действительно хотите удалить комментарий?',
    'Forbidden to reply to own comments':
      'Запрещено отвечать на свои комментарии',
    Updates: 'Обновления',
    'd.': 'дн.',
    'h.': 'ч.',
    'Subscription finished after': 'Конец подписки через',
    'user profile': 'профилe пользователя',
    'Payment in process': 'Платеж в процессе обработки',
    'Error happend with subscription':
      'В процессе активации подписки возникла ошибка',
    'for one month': 'за месяц',
    Refund: 'Возврат',
    Cover: 'Обложка',
    Deleted: 'Удалено',
    Edit: 'Редактировать',
    Available: 'Доступно',
    Complaint: 'Жалоба',
    'Set up email in profile': 'Установите email в профиле',
    'Payment not created': 'Платеж не создан',
    '{count} answer': '{count} ответ',
    'On this comment we have complain':
      'На этот комментарий уже отправили жалобу',
    'Forbidden until {date}': 'Запрещено до {date}',
    'Available only until {date}': 'Доступно только до {date}',
    '{count} answer second': '{count} ответa',
    '{count} answers': '{count} ответов',
    last: 'последний',
    'Payment system {system} not found':
      'Платёжная система {system} не найдена',
    Promotions: 'Акции',
    "You can't review deleted comments":
      'Нельзя оценивать удаленные комментарии',
    'Hide answers': 'Скрыть ответы',
    "You don't have access": 'У вас нет доступа',
    'Return to all comments': 'Все комментарии',
    'Detail information about compain': 'Детальная информация по жалобе',
    'Confirm action': 'Подтвердите действие',
    'Comment not found': 'Комментарий не найден',
    'CommentReview not found': 'Оценка комментария не найдена',
    'Wrong commentReview type': 'Ошибочный тип для оценки комментария',
    'Link forbidden on comments': 'Ссылки запрещены в комментариях',
    "You can't review own comments": 'Нельзя голосовать за свой комментарий',
    Reply: 'Ответить',
    'Must be less then {count} symbols': 'Символов больше чем 500',
    'Forbidden to reply on deleted comments':
      'Запрещено отвечать на удаленные комментарии',
    '500 symbols': '500 символов',
    'Reccurent payment available only for bronze subscription':
      'Реккуретные платежи доступны только для подписки бронза',
    'You have bookmark in this book': 'У вас есть закладка в этой книге',
    'Promotion: Upload cover': 'Акция «Подписка за обложку»',
    'Subscription for cover': '«Подписка за обложку»',
    'Promotion upload cover desc': 'Загрузите обложку и получите подписку',
    'Status: ': 'Статус: ',
    Go: 'Перейти',
    'Correction: ': 'Исправление: ',
    'Card not found notificationId: {notificationId}':
      'Блок не найден для notificationId: {notificationId}',
    'Read all': 'Прочитать всё',
    'Bookmark not found': 'Нет ни одной закладки',
    'Updates not found': 'Нет обновлений',
    'Delete all': 'Удалить всё',
    'This book has cover': 'У данной книги есть обложка',
    'Need to upload image': 'Нужно загрузить изображение',
    'Notification for account without subscription':
      'Оповещение для аккаунта без попдиски',
    'Height image can be more then {number}':
      'Высота изображения должна быть больше чем {number}px',
    Send: 'Отправить',
    'You receive answer to your comment':
      'Вы получили ответ на свой комментарий',
    'Set up all notifications as read':
      'Отметить все уведомнения как прочитанные',
    Prize: 'Вознаграждение!',
    'Need subscription': 'Для просмотра уведомления нужна подписка',
    'Prize desc':
      'Загрузите привлекательную обложку для тайтла и после проверки получите подписку на сутки.',
    'Payment rules': 'Правила оплаты',
    Details: 'Подробнее',
    'Cover successful upload': 'Обложка успешно загружена',
    Type: 'Тип',
    Download: 'Загрузить',
    Status: 'Статус',
    Rating: 'Рейтинг',
    Chapters: 'Главы',
    'Reviews: {reviews}': 'Оценили: {reviews}',
    'Your review': 'Ваша оценка',
    'Send review': 'Поставить оценку',
    Dark: 'Тёмная',
    Light: 'Светлая',
    'Info tab': 'Описание',
    'Comments tab': 'Комментарии',
    '+ yet {number}': '+ ещё {number}',
    'Chapters tab': 'Главы',
    'The last chapter': 'Последняя глава',
    'More detailed': 'Подробнее',
    Title: 'Название',
    TitleEn: 'Название en',
    WRONG_PAGE_INDEX: 'Неправильный порядок страниц',
    'Subscription bronze': 'Подписка бронза',
    'Subscription silver': 'Подписка серебро',
    'Subscription gold': 'Подписка золото',
    'Title not found': 'Заголовок не найден',
    'telegram channel': 'telegram канал',
    'Complain successful send': 'Жалоба успешно отправлена',
    'By justify': 'По ширине',
    'Book page': 'Книга',
    'Send complain': 'Отправка жалобы',
    'Chapter not found': 'Глава не найдена',
    'Content available for people who oldest 18 years':
      'Контент доступен для лиц старше 18 лет',
    'If you need some addition settings, you can write us in ':
      'Если нужны дополнительные настройки, то напишите нам в ',
    'By left': 'По левому',
    'complain.type': 'Причина',
    'complain.reason': 'Причина',
    'Fill up the duplicate page': 'Укажите ссылку на оригинальную главу',
    'Fill up the right index':
      'Укажите правильный порядок, или можете указать ссылку где он является правильным',
    'complain.comment': 'Комментарий',
    'Chapter list': 'Список глав',
    'Tom {tom}. ': 'Том {tom}. ',
    "You can't change this user":
      'Вы не можете редактировать данного пользователя',
    'PWA app for ios': 'PWA приложение для ios',
    'Ranobe the best': 'Каталог книг, ранобэ, новелл, литРПГ - ReNovels',
    'Ranobe the best desc':
      'Каталог книг, новелл, ранобэ, литРПГ, где можно найти хорошую книгу для чтения онлайн, используя удобные фильтры',
    'PWA app for android': 'PWA приложение для android',
    'Genre yaoi available on site ': 'Жанр яой доступен на сайте ',
    'yaoimanga.rf': 'яойманга.рф',
    'If auto-revel set up, the subscription revel every month':
      'Если автопродление включено, то подписка продлевается автоматически со <span class="text-primary font-medium">скидкой</span> согласно тарифного плана',
    Unread: 'Новые',
    Ranobe: 'Ранобэ',
    'Auto-renewal': 'Автопродление',
    'One-time': 'Разово',
    'My profile': 'Мой профиль',
    'Send complaint': 'Отправить жалобу',
    'Say thanks': 'Сказать спасибо',
    'thanks: {count}': 'поблагодарило: {count}',

    'Ranobe keywords':
      'ранобэ, ранобэ онлайн,  Читать ранобэ,  Читать ранобэ онлайн, ранобэ бесплатно, ранобэ романтика, ранобэ по жанрам, ранобэ про любовь, ранобэ русская, ранобэ на русском, перевод ранобэ, лучшая ранобэ, топ ранобэ, каталог ранобэ, популярная ранобэ',
    'Telegram chanel': 'Телеграм канал',
    'Genre yuri available on site ': 'Жанр юри доступен на сайте ',
    'Font size': 'Размер шрифта',
    'Text indent': 'Абзацный отступ',
    'Line height': 'Высота строк',
    'Text align': 'Выравнивание текста',
    'If you want to turn off recurrent payment go to':
      'Отключить механизм автопродления можно в',
    "Don't have results": 'Нет результатов',
    'Fill up more then {number} symbols': 'Введите больше {number}-х символов',
    Video: 'Видео',
    DRAFT: 'Черновик',
    'Hot news': 'Горячие новинки',
    NOT_FOUND: 'Не найден',
    Elements: 'Элементов',
    'Choose value': 'Выберите значение',
    'Status project': 'Статус проекта',
    ACTIVE: 'Онгоинг',
    Year: 'Выпуск',
    COMPLETED: 'Закончен',
    Apply: 'Применить',
    ABANDONED: 'Заморожен',
    LICENSED: 'Лицензировано',
    'PWA definition':
      ' PWA (Progressive Web Application) — web-технология, которая трансформирует сайт в мобильное приложение.',
    'How to install on safari': 'Как установить в сафари',
    'How to install on chrome': 'Как установить в хроме',
    PWA_android_1: 'Откройте браузер Chrome на устройстве Android.',
    PWA_android_2: 'Перейдите на сайт с нужным PWA.',
    PWA_android_3: 'Нажмите Установить.',
    PWA_android_4: 'Следуйте инструкциям на экране.',
    PWA_apple_1:
      'Откройте меню "Поделиться", доступное в нижней или верхней части браузера.',
    PWA_apple_2: 'Нажмите Добавить на главный экран.',
    PWA_apple_3:
      'Подтвердите название приложения; название может быть изменено пользователем.',
    PWA_apple_4:
      'Нажмите Добавить. На iOS и iPadOS закладки на веб-сайты и PWA выглядят на главном экране одинаково.',
    Month: 'Месяц',
    News: 'Новинки',
    'Say first thank you': 'Скажи первым спасибо =)',
    'free chapter': 'бесплатная глава',
    'free chapters': 'бесплатные главы',
    'Top 100 ranobe': 'Топ 100 ранобэ',
    'Top 100 ranobe desc':
      'Самое популярное ранобэ у нас на сайте. Читайте топ 100 лучших ранобэ произведений. Топ ранобэ',
    'Old notification': 'Старое оповещение',
    Snow: 'Снег',
    'Rule 34 Hub 🔥': 'Rule 34 Hub 🔥',
    'Is Read': 'Прочитанные',
    'Month hotmanga': 'Месяц hotmanga',
    'News hotmanga': 'Новинки hotmanga',
    'Set up notifications': 'Настройка нотификаций',
    New: 'Новые',
    Clean: 'Очистить',
    'auto-renewal': 'автопродление',
    'Purchase subscription': 'Покупка подписки',
    'You can buy subscription only on site ':
      'Вы можете приобрести подписку на сайте ',
    'This subscription will be work for both sites':
      'Подписка будет работать на оба сайта.',
    Payback: 'Политика возвратов',
    Close: 'Закрыть',
    'Rule desc':
      'Самая большая коллекция артов и видеороликов Rule34. Никакой цензуры и постоянные обновления!',
    Proceed: 'Перейти',
    Systems: 'Системные',
    'Notification for book is on': 'Уведомления о выходе новых глав включены',
    'Notification for book is off': 'Уведомления о выходе новых глав выключены',
    'If you want to receive notifications about new chapters, you will need to add this title in bookmark with status <span class="text-primary"><b>Read</b></span>':
      'Чтобы получать уведомления о новых главах, добавьте проект в список закладок <span class="text-primary"><b>Читаю</b></span>',
    Added: 'Добавлены',
    chapter: 'глава',
    chapters: 'главы',
    Exclude: 'Исключить',
    Important: 'Важные',
    'Set as read': 'Прочитать',
    'Chapters not published yet': 'Главы ещё не опубликованы',
    Menu: 'Меню',
    "You don't have notifications 🤔": 'Нет ни одного уведомления 🤔',
    Notifications: 'Уведомления',
    'User notifications': 'Уведомления пользователя',
    'ranobe title': 'Читать ранобэ онлайн. Читать новеллу онлайн.',
    'ranobe desc':
      'Читать ранобэ онлайн, фанфики, новеллы. Большая база ранобэ, лайт новел, порно новелл, реинкарнация, перерождение! Читать новеллу с постояными обновлениями',
    'Email successfull update': 'Email успешно обновлён',
    'Public offer': 'Публичная офeрта',
    'Download application': 'Скачать приложение',
    'User with with email already exits':
      'Пользователь с таким email уже существует',
    'Entered value does not match email format': 'Введите валидный email',
    'Public offer desc': 'Публичная офeрта',
    'Update profile': 'Обновите профиль',
    'This field is required': 'Обязательно для заполнения',
    'Confidentiality agreement': 'Соглашение конфиденциальности',
    'Confidentiality agreement desc': 'Соглашение конфиденциальности',
    'Mr. Ninja desc':
      'Зеркала, розыгрыши, голосования и главные новости проектов манга.онлайн, хентайманга.онлайн и hotmanga.me',
    Read: 'Читать',
    'Need to set up email for your profile for receive check about payment':
      'Необходимо установить email для профиля чтобы получить чек об приобритенной подписке',
    'Email for communicate': 'Почта для связи',
    Instraction: 'Инструкция',
    "On tehnical reason payment service temporary doesn't work. Give our apologies":
      'По техническим причинам сервис оплаты временно недоступен. Приносим свои извинения.',
    'Help me': 'Помоги мне',
    Volunteers: 'Добровольцы',
    'How to turn off adv': 'как отключить рекламу?',
    'By likes': 'По лайкам',
    'By bookmarks': 'По закладкам',
    'Adv desc':
      'Наш сайт не показывает всплывающие баннеры и раздражающую рекламу. Отключив AdBlock, вы поможете нам стать лучше. ',
    'DMCA desc':
      'DMCA -  закон, дополняющий законодательство США в области авторского права директивами',
    Volunteers_h6_1: 'Нам необходимы в команду:',
    Volunteers_li_3: 'верстальщик',
    Volunteers_li_4: 'модераторы контента',
    Volunteers_li_5: 'подпиской',
    Volunteers_li_6: 'у аккаунта будет лейба "помощник"',
    Volunteers_li_7:
      'доступ в закрытый чат, где будут обсуждаться новые фичи проекта. Возможность влиять на проект',
    Volunteers_h6_2: 'За помощь мы обеспечим:',
    Volunteers_li_2:
      'дизайнеры (редакторы) обложек. На некоторых тайтлах отсутствуют обложки, поэтому необходимо найти подходящую обложку или создать свою',
    Volunteers_li_1:
      'специалист, который умеет создавать крутые посты (новости проекта, голосовалки, акции и т.д)',
    Volunteers_p3: 'или в наш ',
    Volunteers_p4: 'телеграм канал',
    Volunteers_p2: 'Предложения о помощи высылайте на почту ',
    Volunteers_p1:
      'На нашем проекте очень большая база контента и мы стараемся обеспечить максимальное качество материала, но это не всегда удается. Поэтому...',
    Categories: 'Разделы',
    'Main categories': 'Категории',
    Rate: 'Рейтинг',
    Extend: 'Продлить',
    DMCA: 'DMCA',
    Info: 'Инфо',
    Contacts: 'Контакты',
    Advertisers: 'Рекламодателям',
    'Without censorship': 'Без цензуры',
    Copyright_h6_1: 'Защита авторских прав',
    Copyright_h6_2: 'Уведомление о нарушении авторских прав',
    Copyright_p_1:
      'Мы соблюдаем Закон о защите авторских прав в цифровую эпоху (DMCA) и другие действующие законы об авторских правах.',
    Copyright_p_3:
      'Если вы считаете, что материал на сайте {site} нарушает ваши авторские права, вы можете написать уведомление о нарушении прав и отправить его на адрес нашего агента {email} и сервис {site} незамедлительно осуществит блокировку перечисленных в уведомлении материалов.',
    Copyright_p_2:
      'Мы можем не знать о том, что использование соответствующих результата интеллектуальной деятельности или средства индивидуализации, содержащихся в таком материале, является неправомерным. Поэтому, в случае получения в письменной форме заявления правообладателя о нарушении интеллектуальных прав с указанием страницы сайта и (или) сетевого адреса в сети "Интернет", на которых размещен такой материал, мы своевременно принимаем необходимые и достаточные меры для прекращения нарушения интеллектуальных прав.',
    Copyright_p_11:
      'После получения указанного выше уведомления о нарушении сервис {site} предпринимает действия в течение 3 (трех) рабочих дней.',
    Copyright_p_12:
      '{site} оставляет за собой право прекратить предоставление сервиса владельцам аккаунтов, которые были замечены в многократных нарушениях авторских прав.',
    Copyright_p_10:
      'Заявление, сделанное ввиду возможных штрафных санкций за предоставление ложных сведений, что представленные вами факты не содержат ошибок и искажений, и вы имеете право действовать от лица владельца авторских прав.',
    Copyright_p_9:
      'Утверждение, что вы действительно считаете, что использование данного материала в таком виде не разрешено ни владельцем авторских прав, ни его агентом, ни законодательством.',
    Copyright_p_8:
      'Информация о том, как связаться с вами, например адрес электронной почты, почтовый адрес.',
    Copyright_p_7:
      'Указание на материал, нарушающий авторские права или являющийся субъектом поведения, нарушающего авторские права, каковой материал должен быть удален с серверов {site} или заблокирован для доступа. Вы также должны предоставить исходный адрес (URL) этого материала.',
    Copyright_p_6:
      'Указание на работы (материалы), в отношении которых нарушены права. В случае, когда уведомление сообщает о нарушениях авторского права на несколько таких материалов (текстов, изображений), в нем должны быть приведены указания на каждый из них.',
    Copyright_p_5:
      'Материальную или электронную подпись лица, имеющего право представлять интересы человека, чьи авторские права на материал были нарушены.',
    Copyright_p_4:
      'Часть 512(c) Акта об авторских правах (Digital Millennium Copyright Act) предъявляет определенные требования к форме этого уведомления. В соответствии с этим, оно должно быть предоставлено в письменном виде и обязательно содержать следующую информацию:',
    'Copyright holders': 'Правообладателям',
    'Copyright holders desc':
      'Все правообладатели могут обратиться с жалобой к нам на почту',
    'Ask question': 'Задать вопрос',
    'Always ready to answer on questions': 'Всегда готовы ответить на вопросы',
    'Will be free <br /> {date}': 'Станет бесплатной <br /> {date}',
    Back: 'Назад',
    'Access only in subscription': 'Доступна только в подписке',
    'Tell about error in channel https://t.me/topmangachat/4':
      'Сообщите об ошибке в канал https://t.me/topmangachat/4',
    Bronze: 'Бронза',
    'If you want to see content need to auth':
      'Для просмотра нужно авторизироваться',
    Silver: 'Серебро',
    authorize: 'авторизоваться',
    'with subscription': 'по подпискe',
    'access to private ': 'доступ к закрытым ',
    'access to private chapters': 'доступ к закрытым главам',
    ' titles': ' тайтлам',
    'On territory RU this manga fobbiden.':
      'На территории РФ данный тайтл недоступен.',
    'Details ': 'Детали ',
    'On territory RU this manga fobbiden. Details ':
      'На территории РФ данный тайтл недоступен. Подробности ',
    'You select genres which forbidden on Ru. Details <a class="text-primary" target="_blank" href="{link}">here</a>':
      'Вы выбрали жанры запрещенные на территории РФ. Подробности <a class="text-primary" target="_blank" href="{link}">здесь</a>',
    'Titles with genres: yaoi, yuri, ... available in our mirrow':
      'Тайтлы с жанрами: яой, юри, ... доступны на нашем <a class="text-primary" href="https://hotmanga.me/manga/">зеркале</a>',
    'Don"t have subscription :(': 'Нет подписки :(',
    'This title available only with subscription':
      'Данный тайтл доступ только по подписке',
    here: 'здесь',
    Arrange: 'Оформить',
    'Arrange subscription': 'Оформить подписку',
    'Error is happend': 'Возникла ошибка',
    'Payment is processing': 'Платёж в обработке',
    'Payment with error': 'Возникла ошибка при обработке платежа',
    'Payment is completed': 'Платёж успешно обработан',
    'Profile user: {name}': 'Профиль пользователя: {name}',
    'User profile': 'Профиль пользователя',
    Buy: 'Купить',
    tagging: 'пометка',
    '* These offers are not a public offer agreement and may be changed unilaterally by the site administration. Subscription it will be canceled if the user distributes content on other resources.':
      '* Подписка будет аннулирована, если пользователь будет распространять контент на других ресурсах.',
    economy: 'экономия',
    '1 month': '1 месяц',
    '3 months': '3 месяцa',
    sponsor: 'спонсор',
    subscribe: 'подписчик',
    '1 year': '1 год',
    'without ads': 'без рекламы',
    Gold: 'Золото',
    Logo: 'Логотип',
    'Purchase a subscription to read without adv!': 'Приобретите подписку!',
    'Thanks to the subscription, you help the site to develops':
      'Благодаря подписке вы получаете доступ ко всему контенту сайта',
    Subscription: 'Подписка',
    'Subscription for users': 'Подписка для пользователей',
    'Add review': 'Оценить',
    'Not found': 'Не найдено',
    'Review 5': 'Отлично',
    'Review 4': 'Хорошо',
    'Review 3': 'Неплохо',
    'Review 2': 'Плохо',
    'Review 1': 'Ужасно',
    Likes: 'Лайков',
    'Bookmarks count': 'Закладок',
    'Was read': 'Прочитано',
    'Tom {tom} Chapter {chapter}': 'Том {tom} Глава {chapter}',
    'Tom {tom}, Chapter {chapter}': 'Том {tom}. Глава {chapter}',
    '{book} Tom {tom} Chapter {chapter}': '{book} Том {tom} Глава {chapter}',
    '{book} Chapter {chapter}': '{book} Глава {chapter}',
    'Read {tom} tom {chapter} chapter {book} on site {site}':
      'Читать {tom} том {chapter} главу {book} на сайте {site}',
    '{book} Chapter {chapter} Slide {slide}':
      '{book}. Глава {chapter}. Слайд {slide}',
    'Add bookmark': 'Добавить в закладки',
    Bookmarks: 'Закладки',
    'Change theme': 'Смена темы',
    Settings: 'Настройки',
    Profile: 'Профиль',
    Username: 'Имя пользователя',
    Save: 'Сохранить',
    'List channels': 'Список каналов',
    'Successfull save': 'Успешно сохранено',
    'User settings': 'Настройки пользователя',
    'Required to fill up': 'Обязательно для заполнения',
    Catalog: 'Каталог',
    Cancel: 'Отменить',
    'Choose image': 'Выберите изображение',
    'Top 100': 'Топ 100',
    'How top is created':
      'Топ формируется на основе лайков, просмотров, закладок и других вещей',
    'Top 100 hentai manga': 'Топ 100 популярной хентай манги',
    'Top 100 yaoi manga': 'Топ 100 популярной яой манги',
    'Top 100 yaoi desc':
      'Топ 100 популярной хентай манги, манхвы и маньхуа на сайте! Тут собраны лучшие тайтлы по вкусам пользователей со всего сайта чтобы вы нашли для себя что-то новое и интересное',
    'Top 100 hentai desc':
      'Топ 100 популярной хентай манги, манхвы и маньхуа на сайте! Тут собраны лучшие тайтлы по вкусам пользователей со всего сайта чтобы вы нашли для себя что-то новое и интересное',
    'Top 100 desc':
      'Топ 100 популярной манги, манхвы и маньхуа на сайте! Тут собраны лучшие тайтлы по вкусам пользователей со всего сайта чтобы вы нашли для себя что-то новое и интересное',
    'Top 100 manga': 'Топ 100 популярной манги',
    'Wishes and bugs here': 'Пожелания и баги сюда',
    'Our channels': 'Наши каналы',
    'Our channels desc in page':
      'Будем всем рады в нашем канале <a class="text-primary hover:text-hover" target="_blank" href="https://t.me/topmangachat"><b>Домик для манги</b></a>. Прямая связь с разработчиками проекта. Ниже представлен полный список наших каналов 😊',
    'Our channels desc': 'Здесь собраны telegram каналы нашей команды',
    Top: 'Топ',
    HotCosplay: 'Hot Cosplay 🔥',
    'HotCosplay desc':
      'Горячий косплей на все вкусы! Ежедневные обновления контента, ссылки на страницы моделей и указания фэндомов. Нравятся девочки в костюмах? Тогда тебе сюда!',
    'User bookmarks': 'Закладки пользователя',
    "You don't have bookmarks": 'У вас нет закладок',
    'In plan': 'В планах',
    'Available formats: {formats}': 'Доступные форматы: {formats}',
    'Available size: 1mb': 'Доступный размер: 1МБ',
    'This value already exists': 'Такое значение уже существует',
    Logout: 'Выйти',
    No: 'Нет',
    Yes: 'Да',
    'To view the content, you must': 'Для просмотра контента необходимо',
    Authorized: 'Авторизоваться',
    'The content available for people over then 18 ages':
      'Контент доступен для лиц старше 18 лет',
    'Go in settings': 'Перейти в настройки',
    Choose: 'Выбрать',
    '18+': 'Ваш возраст больше 18 лет?',
    'Are you age more then 18 ages?': 'Вам больше 18-ти лет?',
    Reading: 'Читаю',
    Abandoned: 'Брошено',
    Favorite: 'Любимые',
    'Manga online': 'Манга онлайн',
    DUPLICATE_CHAPTER: 'Дубликат главы',
    LOST_CHAPTER_CONTENT: 'Отсутствует контент в главе',
    Register: 'Зарегистироваться',
    'Signin in account': 'Войти в аккаунт',
    'Send Complain': 'Отправка жалобы',
    'throw vk': 'через вконтакте',
    'throw google': 'через google',
    Login: 'Войти',
    'Login in account': 'Войти в аккаунт',
    Loading: 'Загрузка...',
    Description: 'Описание',
    Statistic: 'Статистика',
    Comments: 'Комментарии',
    'In this collection books not found 😨':
      'В этой коллекции книги не найдены 😨',
    'Not result 🤔': 'Нет результов 🤔',
    'show from start': 'показать с начала',
    'show from end': 'показать с конца',
    'For authorization you use another method, auth use it':
      'Для авторизации вы использовали другой способ, авторизуйтесь через него',
    'Some problem with database': 'Не удалось создать аккаунт в базе',
    'Error when data was processed': 'Ошибка при обработке данных',
    "This method don't have confirm email, need to confirm email":
      'У данного способа не подтвержден email, подтвердите его',
    "Don't have account?": 'Нет учетной записи?',
    'Do you have account?': 'Уже есть аккаунт?',
    'Main page description hentai':
      'Читать лучшую хентай мангу, манхву, маньхуа на русском. Без границ и цензуры! Лучшая библиотека хентай манги. Мы знаем, что вы ищите 👀 наруто, геншин, порно, hmanga, манга 18, без цензуры, секс, инцест, яой, импакт, hentai manga, моя геройская академия',
    'Main page description':
      'Читать топ мангу, манхву, маньхуа на русском. Лучшая библиотека манги. Мы знаем, что вы ищите 👀',
    'Best hentai manga online': 'Лучшая хентай манга онлайн',
    Action: 'Действие',
    'More manga': 'Больше манги',
    'Manga, manhwa online, manhua online, manhwa, manhua, manga online, read manga, read manga online, manga by genres, manga on russian, translate manga, best manga, top manga, catalog manga, popular manga':
      'манга, манхва онлайн, маньхуа онлайн, манхва, маньхуа, манга онлайн, читать мангу, читать мангу онлайн, манга по жанрам, манга на русском, перевод манга, лучшая манга, топ манга, каталог манга, популярная манга',
    Delete: 'Удалить',
    "You don't have bookmarks 🤔": 'Нет ни одной закладки 🤔',
    'Description not set up': 'Описание не задано',
    Unwrap: 'Развернуть',
    'Indonesia comics': 'Индонезийский комикс',
    Manhua: 'Маньхуа',
    Manga: 'Манга',
    'Hentai manga': 'Хентай манга',
    'Last updates': 'Последние обновления',
    Manhwa: 'Манхва',
    'OEL-manga': 'OEL-манга',
    'Ru-manga': 'Руманга',
    'Western comics': 'Комикс западный',
    'List of chapters': 'Список глав',
    Another: 'Другое',
    'Books not found 🙄': 'Книги не найдены 🙄',
    'Sign in': 'Войти',
    'Sign in and get more abilities':
      'Войдите на сайт, чтобы получить больше возможностей.',
    Tags: 'Теги',
    More: 'Ещё',
    Theme: 'Тема',
    Search: 'Поиск',
    'Search favorite manga': 'Поиск любимой манги',
    'What are you search?': 'Что ищем?',
    'more tags': 'ещё теги...',
    'some seconds ago': 'несколько секунд назад',
    'less then a minute': 'меньше минуты',
    'Need to auth': 'Необходимо авторизоваться',
    'Error happend': 'Произошла ошибка',
    'Section in work 🤗': 'Раздел в разработке 🤗',
    'Container width': 'Ширина контейнера',
    'Settings for reader': 'Настройки читалки',
    'Thanks!': 'Спасибо!',
    'They said thank you: ': 'Сказали спасибо: ',
    'By new': 'По новизне',
    'By updates': 'По обновлениям',
    Filters: 'Фильтры',
    Types: 'Типы',
    Series: 'Серии',
    Persons: 'Персонажи',
    'Hentai manga the best': 'Хентай манга лучшее',
    'Manga the best': 'Манга лучшее',
    "Read the best hentai manga, manhwa, manhua on russion Without boards and censorships! Best library's of hentai manga Convenient filters":
      'Читать лучшую хентай мангу, манхву, маньхуа на русском. Без границ и цензуры! Лучшая библиотека хентай манги. Удобная фильтрация',
    Genres: 'Жанры',
    "Read top manga, manhwa, manhua on russian Best library's of manga Convenient filters":
      'Читать топ мангу, манхву, маньхуа на русском. Лучшая библиотека манги. Удобная фильтрация',
    'For your request books not found 🤔':
      'По вашему запросу книги не найдены 🤔',
    "Mobile version doesn't have settings yet 😊":
      'В мобильной версии нет ещё настроек 😊',
    Adult: 'Взрослый',
    'Site theme': 'Тема сайта',
    "This site contents content with don't available for people whose age less then 18 ages":
      'Данный сайт содержит в себе элементы, которые недоступны для просмотра лицам младше 18-ти лет.',
    'Content only for adult': 'Содержание только для взрослых',
    'I have 18 ages': 'Мне есть 18 лет',
    "We'll be back soon!": 'Мы скоро вернёмся',
    'Service unavailable': 'Сервис недоступен',
    'Sorry for the inconvenience, We’re performing some maintenance at the moment, We’ll be back up shortly!':
      'Приносим извинения за причиненные неудобства. В данный момент мы проводим кое-какие ремонтные работы. Мы скоро вернёмся!',
    "I don't have 18 ages": 'Мне нет 18 лет',
    'Anime Wallpapers Everyday': 'Anime Wallpapers Everyday',
    Follow: 'Присоединиться',
    Advertisers_p2: 'Для сотрудничества писать на почту ',
    Advertisers_p1:
      'Данный проект сотрудничает напрямую с рекламодателями. Площадкам предоставляющих интересы рекламодателей просьба не писать, т.к мы не сотрудничаем с площадками.',
    subscribers: 'подписчиков',
    'subscriber-2-4': 'подписчика',
    subscriber: 'подписчик',
    'Home for manga': 'Домик для манги',
    'Volunteers desc':
      'Любой желающий может стать добровольцем на проекте, оставить заявку можно на проекте',
    'Advertisers desc':
      'Проект рабоатает с рекламодателями и по требованию предоставит медиа-кит для рекламодателей',
    'channel of support': 'канал саппорта',
    'Error with loading content!': 'Не удалось загрузить контент!',
    '1. Try to reload page': '1. Попробуйте перезагрузить страницу',
    '2. If the problem leave then send link in ':
      '2. Если проблема осталась, то скиньте ссылку <br /> на главу в ',
    'Ranobe desc':
      'Следите за новостями и первыми узнавайте о новых поступлениях новелл, акциях и технических обновлениях. Ну и конечно же заряжайтесь позитивом вместе с нашими развлекательными постами!',
    'Home for manga desc':
      'Уютное убежище. Манга, манхва, маньхуа… и даже хентай. Что бы вас ни интересовало, здесь вы обязательно найдёте собеседников! В качестве бонуса мы предлагаем прямую связь с разработчиками проекта.',
    'Anime Wallpapers Everyday desc':
      'Любите менять обои на рабочем столе своего ноутбука или на смартфоне? Являетесь поклонником аниме стилистики? Это место для вас! Большой выбор, множество размеров, удобная система навигации по тегам и ежедневные обновления.',
    'yaoi title':
      'Яой манга. Читать яой манхвы онлайн. Лучшая яой манга онлайн!',
    'yaoi description':
      'Яой у нас на сайте, мы знаем что вы ищите 👀 гей манга, яой манга онлайн, читать мангу яой, хентай манга яой, яой порно манга, манга яой секс, яой манга 18, yaoi manga, яой манхвы, популярная яой манга',
    'Need auth': 'Необходимо авторизоваться',
    "You don't have permission": 'У вас нет прав доступа',

    // EnChapterType
    'EnChapterType.CHAPTER': 'Глава',
    'EnChapterType.AFTERWORD': 'Послесловие',
    'EnChapterType.EPILOGUE': 'Эпилог',
    'EnChapterType.EXTRA': 'Экстра',
    'EnChapterType.ILLUSTRATIONS': 'Иллюстрации',
    'EnChapterType.PROLOGUE': 'Пролог',
    'EnChapterType.ADDITIONAL': 'Дополнительная история',

    'EnCommentComplainReason.FLUD': 'Флуд',
    'EnCommentComplainReason.OFFENSE': 'Оскорбление',
    'EnCommentComplainReason.SPAM': 'Спам',
    'EnCommentComplainReason.SPOILER': 'Спойлер',
    'EnCommentComplainReason.SWEARING': 'Не содержит смысла',
    'EnCommentComplainReason.WITHOUT_SENSE': 'Избыточная ненормативная лексика',
    'EnCommentComplainReason.ANOTHER': 'Другое',

    // select
    'select.types': 'Выберите типы',
    'select.exclude_types': 'Исключить типы',
    'select.genres': 'Выберите жанры',
    'select.exclude_genres': 'Исключить жанры',
    'select.tags': 'Выберите теги',
    'select.reason': 'Выберите причину',
    'select.exclude_tags': 'Исключить теги',
    'select.statuses': 'Выберите статусы',
    'select.type': 'Выберите причину',
    'select.exclude_statuses': 'Исключить статусы',

    'EnCorrectionStatus.NEW': 'Новый',
    'EnCorrectionStatus.APPROVED': 'Утверждено',
    'EnCorrectionStatus.NOT_APPROVED': 'Отклонено',

    // validation
    'validation.required': 'Обязательное поле',
    'validation.notNull': 'Должно быть заполненным',
    'validation.notType': 'Должно быть числом',
    'validation.oneOf: {values}': 'Должно быть одним из значений: {values}',

    // admin
    'Record not deleted': 'Запись не удалена',
  }

  let title = titles[key] ?? ''

  if (params) {
    Object.keys(params).map((param) => {
      title = title.replaceAll(`{${param}}`, String(params[param]))
    })
  }
  return title
}
